<template>
  <!-- deliverDate -->
  <div class="d-flex justify-content-center m-3 btn-group">
    <input
      v-model="chequeDate"
      type="date"
      class="form-control"
      placeholder="Дата чека"
      @input="getKkmTasks"
    />
  </div>
  <div
    class="
      list-group-item list-group-item-action
      border
      bg-success
      text-light
      mt-3
    "
  >
    <div class="row">
      <div class="col-sm">
        <!-- <div class="row"><small class="text-muted">Сумма</small></div> -->
        <div class="row">
          <h5 class="mb-1 h5 border-0">ИТОГ ЗА ДЕНЬ</h5>
        </div>
      </div>
      <div class="col-sm">
        <div class="row"><small>НАЛ</small></div>
        <div class="row">
          <h5 class="mb-1 h5 border-0">
            {{ sum.nal }}
          </h5>
        </div>
      </div>
      <div class="col-sm">
        <div class="row"><small>КАРТА</small></div>
        <div class="row">
          <h5 class="mb-1 h5 border-0">
            {{ sum.karta }}
          </h5>
        </div>
      </div>
      <div class="col-sm">
        <div class="row"><small>QR</small></div>
        <div class="row">
          <h5 class="mb-1 h5 border-0">
            {{ sum.qr }}
          </h5>
        </div>
      </div>
      <div class="col-sm">
        <div class="row"><small>САЙТ</small></div>
        <div class="row">
          <h5 class="mb-1 h5 border-0">
            {{ sum.paymentSite }}
          </h5>
        </div>
      </div>
    </div>
  </div>

  <div class="list-group" v-for="(kkmTask, key) in kkmTasks" :key="key">
    <div
      class="list-group-item list-group-item-action border border-primary mt-3"
    >
      <div>
        <!-- Сумма заказа по виду оплат -->
        <div
          class="row"
          :class="{
            'text-success': kkmTask.cheque,
            'text-danger': !kkmTask.cheque,
          }"
        >
          <div class="col-sm">
            <div class="row"><small class="text-muted">Заказ</small></div>
            <div class="row">
              <h5 class="mb-1 h5 border-0">
                {{ kkmTask.order.orderNumber }}
              </h5>
            </div>
          </div>
          <div class="col-sm">
            <div class="row"><small class="text-muted">НАЛ</small></div>
            <div class="row">
              <h5 class="mb-1 h5 border-0" :class="{'text-dark': 'isRefund' in kkmTask && kkmTask.isRefund}" v-if="kkmTask.order.nal">
                {{ kkmTask.order.totalCost }}
              </h5>
            </div>
          </div>
          <div class="col-sm">
            <div class="row"><small class="text-muted">КАРТА</small></div>
            <div class="row">
              <h5 class="mb-1 h5 border-0" :class="{'text-dark': 'isRefund' in kkmTask && kkmTask.isRefund}" v-if="kkmTask.order.karta">
                {{ kkmTask.order.totalCost }}
              </h5>
            </div>
          </div>
          <div class="col-sm">
            <div class="row"><small class="text-muted">QR</small></div>
            <div class="row">
              <h5 class="mb-1 h5 border-0" :class="{'text-dark': 'isRefund' in kkmTask && kkmTask.isRefund}" v-if="kkmTask.order.qr">
                {{ kkmTask.order.totalCost }}
              </h5>
            </div>
          </div>

          <div class="col-sm">
            <div class="row"><small class="text-muted">САЙТ</small></div>
            <div class="row">
              <h5 class="mb-1 h5 border-0" :class="{'text-dark': 'isRefund' in kkmTask && kkmTask.isRefund}" v-if="kkmTask.order.paymentSite">
                {{ kkmTask.order.totalCost }}
              </h5>
            </div>
          </div>

        </div>

        <!-- Инфо и заказе -->
        <div class="row">
          <div class="col-sm">
            <div class="row"><small class="text-muted">Клиент</small></div>
            <div class="row">
              <small>{{ kkmTask.order.client }}</small>
            </div>
          </div>
          <div class="col-sm">
            <div class="row"><small class="text-muted">Курьер</small></div>
            <small>{{ kkmTask.order.courier }}</small>
          </div>
          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Пользователь</small>
            </div>
            <small>{{ kkmTask.user.username }}</small>
          </div>
        </div>

        <!-- Чек пробит/не пробит -->
        <div class="row">
          <div class="col-sm" v-if="kkmTask.cheque">
            <router-link
              :to="{ name: 'cheque', params: { chequeId: kkmTask._id } }"
              target="_blank"
            >

              <small v-if="'isRefund' in kkmTask && kkmTask.isRefund" class="text-light bg-dark">Чек возврата пробит</small>
              <small v-if="!('isRefund' in kkmTask && kkmTask.isRefund)" class="text-light bg-success">Чек пробит</small>

            </router-link>
          </div>

          <div class="col-sm" v-if="!kkmTask.cheque">
            <div class="row">
              <small class="text-light bg-danger">Чек не пробит</small>
            </div>
            <div class="row">
              <small class="text-danger">{{ kkmTask.resData }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <pre>
    {{ kkmTasks }}
  </pre> -->
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  setup() {
    let kkmTasks = ref([]);
    let chequeDate = ref("");
    let sum = ref({
      karta: 0,
      nal: 0,
      qr: 0,
      paymentSite: 0,
    });

    const getKkmTasks = async () => {
      console.log("/getKkmTasks");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getKkmTasks/" + chequeDate.value,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa(
                localStorage.getItem("username") +
                  ":" +
                  localStorage.getItem("password")
              ),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        kkmTasks.value = await res.json();
        calculateSum();
      }
    };

    const calculateSum = () => {
      for (const kkmTask of kkmTasks.value) {
        if (!kkmTask.cheque || 'isRefund' in kkmTask && kkmTask.isRefund) continue;
        if (kkmTask.order.nal) sum.value.nal += kkmTask.order.totalCost;
        if (kkmTask.order.karta) sum.value.karta += kkmTask.order.totalCost;
        if (kkmTask.order.qr) sum.value.qr += kkmTask.order.totalCost;
        if (kkmTask.order.paymentSite) sum.value.paymentSite += kkmTask.order.totalCost;
      }
    };

    onMounted(async () => {
      let today = new Date().toISOString().slice(0, 10);
      chequeDate.value = today;
      await getKkmTasks();
    });

    return {
      getKkmTasks,
      kkmTasks,
      chequeDate,
      sum,
    };
  },
};
</script>