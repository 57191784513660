<template>
  <!-- deliverDate -->
  <div class="d-flex justify-content-center m-3 btn-group">
    <input
      v-model="deliverDate"
      type="date"
      class="form-control"
      placeholder="Дата доставки"
      @input="getOrders"
    />
    <button class="btn btn-warning" @click="showMap()">КАРТА</button>
  </div>

  <OrderMap v-if="mapVisibility" :orders="orders" @openOrder="onOpenOrder" />

  <!-- Фильтр по статусам -->
  <div class="d-flex justify-content-center">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label class="btn btn-light">
        <input
          type="radio"
          name="options"
          value="showAll"
          autocomplete="off"
          v-model="statusFilter"
        />
        Все
      </label>
      <label class="btn btn-light">
        <input
          type="radio"
          name="options"
          value="showOpened"
          autocomplete="off"
          v-model="statusFilter"
        />
        Открытые
      </label>
      <label class="btn btn-light">
        <input
          type="radio"
          name="options"
          value="showClosed"
          autocomplete="off"
          v-model="statusFilter"
        />
        Закрытые
      </label>
    </div>
  </div>

  <!-- search -->
  <div id="searchGroup" class="d-flex justify-content-center m-3 btn-group">
    <input
      v-model="searchText"
      type="text"
      class="form-control"
      placeholder="Поиск"
      @input="findOrders"
    />
  </div>

  <div id="tableOfResults" class="m-3" v-if="statusFilter == 'showAll'">
    <ul class="list-group">
      <li class="list-group-item list-group-item-primary">
        Всего заказов: <b>{{ tableOfResults.qtyOfOrders }}</b>
      </li>
      <li class="list-group-item list-group-item-primary">
        Доставлено заказов:
        <b>{{
          tableOfResults.qtyOfClosedOrders - tableOfResults.qtyOfCancelledOrders
        }}</b>
      </li>
      <li class="list-group-item list-group-item-primary">
        Отменено заказов: <b>{{ tableOfResults.qtyOfCancelledOrders }}</b>
      </li>
      <li class="list-group-item list-group-item-primary">
        Принято НАЛ: <b>{{ tableOfResults.nal }}</b>
      </li>
      <li class="list-group-item list-group-item-primary">
        Принято КАРТА: <b>{{ tableOfResults.karta }}</b>
      </li>
      <li class="list-group-item list-group-item-primary">
        Принято QR: <b>{{ tableOfResults.qr }}</b>
      </li>
      <li class="list-group-item list-group-item-primary">
        Принято САЙТ: <b>{{ tableOfResults.paymentSite }}</b>
      </li>
      <li class="list-group-item list-group-item-primary">
        Принято БЕЗ ЧЕКА: <b>{{ tableOfResults.bezcheka }}</b>
      </li>
      <li class="list-group-item list-group-item-primary">
        Всего бутылей:
        <b
          >{{ tableOfResults.bottles }} <br />
          (КЖ: {{ tableOfResults.vodaKzh }}, ВАРТ:
          {{ tableOfResults.vodaVart }}, КЛ: {{ tableOfResults.vodaKl }})</b
        >
      </li>
      <li class="list-group-item list-group-item-primary">
        Отгружено бутылей:
        <b
          >{{ tableOfResults.bottlesDelivered }}
          <br />
          (КЖ: {{ tableOfResults.bottlesDeliveredKzh }}, ВАРТ:
          {{ tableOfResults.bottlesDeliveredVart }}, КЛ:
          {{ tableOfResults.bottlesDeliveredKl }})
        </b>
      </li>
      <li class="list-group-item list-group-item-primary">
        Возвращено бутылей: <b>{{ tableOfResults.bottlesReturned }}</b>
      </li>
      <li class="list-group-item list-group-item-primary">
        <b>Сопутствующие товары и услуги</b>
        <div
          v-for="(index, value) in tableOfResults.relatedProducts"
          :key="index"
        >
          {{ value }}: {{ index }}
        </div>
      </li>
    </ul>
  </div>

  <!-- orders -->
  <div
    class="accordion"
    :class="{ 'blink': selectedOrderId == order._id }"
    :id="`order-${order._id}`"
    v-for="order in orders"
    :key="order._id"
  >
    <div class="accordion-item" v-if="orderVisiblity(order)">
      <h2 class="accordion-header" :id="'heading' + order._id">
        <button
          :id="order._id"
          :style="{ 'background-color': orderColor(order) }"
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#collapse' + order._id"
          aria-expanded="false"
          :aria-controls="'collapse' + order._id"
        >
          <div class="row w-100">
            <div class="col">
              <b
                >{{ order.deliveryTimeFloor.slice(11, -3) }} -
                {{ order.deliveryTimeCeil.slice(11, -3) }}
                {{ order.deliveryAdress }}</b
              >
              <p v-if="order.sopytka" class="mb-0">СОПУТКА</p>
              <p v-if="order.comment" class="mb-0">К: {{ order.comment }}</p>
            </div>

            <div class="col col-3" id="changableStatus">
              {{ order.clientType }}
              <br />
              {{ order.orderStatus }}
            </div>
          </div>
        </button>
      </h2>
      <div
        :id="'collapse' + order._id"
        class="accordion-collapse collapse"
        :aria-labelledby="'heading' + order._id"
        :data-bs-parent="`#order-${order._id}`"
      >
        <!-- order data -->
        <div class="accordion-body">
          <li>{{ order.client }}</li>
          <li>
            Коммент. точки дост.: <b>{{ order.deliveryAdressComment }}</b>
          </li>
          <li>
            Комментарий: <b>{{ order.comment }}</b>
          </li>
          <li>Тип оплаты: {{ order.paymentWay }}</li>
          <li v-if="userAdmin()">E-mail: {{ order.email }}</li>
          <li v-if="userAdmin()">Номер заказа: {{ order.orderNumber }}</li>
          <li v-if="userAdmin()">Водитель: {{ order.courier }}</li>
          <li>Итоговая сумма заказа: {{ order.totalCost }} р.</li>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr
                  v-for="(product, productIndex) in order.goods"
                  :key="product._id"
                  :class="{
                    currentProduct: isCurrentProduct(order._id, productIndex),
                  }"
                >
                  <td>{{ product.productName }}</td>
                  <td>Цена: {{ product.productPrice }}</td>
                  <td>
                    Кол-во: {{ product.productQty }}
                    <button
                      class="btn btn-warning"
                      data-order="${order._id}"
                      @click="startScan(order, productIndex)"
                    >
                      M{{ "marks" in product ? product.marks.length : 0 }}
                    </button>
                  </td>
                  <td>Итого: {{ product.productSum }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            v-if="
              currentOrder &&
                currentOrder._id == order._id &&
                currentProductIndex >= 0 &&
                'marks' in order.goods[currentProductIndex]
            "
          >
            <span class="fw-bold">МАРКИ:</span>
            <p
              v-for="(mark, index) in order.goods[currentProductIndex].marks"
              :key="index"
              class="mb-1"
            >
              <small
                >{{ mark }}
                <button
                  class="btn btn-sm btn-danger"
                  @click="removeMark(order, currentProductIndex, index)"
                >
                  X
                </button>
              </small>
            </p>
          </div>
        </div>

        <div class="d-flex justify-content-center form-group ms-3 me-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-lg"
              >Возвращено бут.</span
            >
          </div>
          <input
            v-model="order.bottlesReturned"
            type="text"
            class="form-control"
            aria-label="Large"
            aria-describedby="inputGroup-sizing-sm"
            @focus="$event.target.select()"
          />
        </div>

        <div class="d-flex justify-content-center form-group ms-3 me-3 mt-3">
          <span class="input-group-text" id="inputGroup-sizing-lg"
            >Комментарий</span
          >
          <textarea
            type="text"
            class="form-control"
            aria-label="Large"
            aria-describedby="inputGroup-sizing-sm"
            v-model="order.driverComment"
          ></textarea>
        </div>

        <div class="form-check ms-3" v-if="userAdmin()">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="order.needCheque"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Пробивается чек
          </label>
        </div>

        <div class="form-check ms-3" v-if="userAdmin()">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="order.chequeWithoutMarks"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Пробить без марок
          </label>
        </div>

        <!-- Кнопки или спиннер -->
        <div
          v-if="order.spinner"
          class="d-flex justify-content-center form-group ms-3 me-3 mt-3"
        >
          <div class="spinner-border text-primary" role="status"></div>
        </div>

        <div v-if="!order.spinner">
          <!-- Кнопки вид оплаты -->
          <div
            class="d-flex justify-content-center m-3 btn-group"
            v-if="payTypeVisiblity(order)"
          >
            <button
              type="button"
              class="btn"
              :class="{
                'btn-primary': order.nal,
                'btn-outline-primary': !order.nal,
              }"
              data-paytype="nal"
              @click="buttonOnClick($event, order)"
            >
              НАЛ
            </button>
            <button
              type="button"
              class="btn"
              :class="{
                'btn-primary': order.karta,
                'btn-outline-primary': !order.karta,
              }"
              data-paytype="karta"
              @click="buttonOnClick($event, order)"
            >
              КАРТА
            </button>
            <button
              type="button"
              class="btn"
              :class="{
                'btn-primary': order.qr,
                'btn-outline-primary': !order.qr,
              }"
              data-paytype="qr"
              @click="buttonOnClick($event, order)"
            >
              QR
            </button>

            <button
              type="button"
              class="btn"
              :class="{
                'btn-primary': order.paymentSite,
                'btn-outline-primary': !order.paymentSite,
              }"
              data-paytype="paymentSite"
              @click="buttonOnClick($event, order)"
            >
              САЙТ
            </button>

            <button
              type="button"
              class="btn"
              :class="{
                'btn-primary': order.bezcheka,
                'btn-outline-primary': !order.bezcheka,
              }"
              data-paytype="bezcheka"
              @click="buttonOnClick($event, order)"
            >
              БЕЗ ЧЕКА
            </button>
          </div>

          <!-- Кнопка фискализация -->
          <div
            class="d-flex justify-content-center m-3 btn-group"
            v-if="fiscalizeButtonVisiblity(order)"
          >
            <button
              type="button"
              class="btn btn-warning"
              data-order="${order._id}"
              :disabled="!isMarksCollected(order)"
              @click="buttonOnClick($event, order)"
            >
              <span v-if="isMarksCollected(order)">
                ПРОБИТЬ ЧЕК
              </span>
              <span v-if="!isMarksCollected(order)">
                ОТСКАНИРУЙТЕ МАРКИ
              </span>
            </button>
          </div>

          <!-- Кнопка показать чек -->
          <div
            class="d-flex justify-content-center m-3 btn-group"
            v-if="showChequeButtonVisiblity(order)"
          >
            <button
              type="button"
              class="btn btn-success"
              data-order="${order._id}"
              @click="buttonOnClick($event, order)"
            >
              ПОКАЗАТЬ ЧЕК
            </button>
          </div>

          <!-- Кнопка возврата -->
          <div
            class="d-flex justify-content-center m-3 btn-group"
            v-if="fiscalizeRefundButtonVisiblity(order)"
          >
            <button
              type="button"
              class="btn btn-info"
              data-order="${order._id}"
              @click="buttonOnClick($event, order)"
            >
              <span>
                ПРОБИТЬ ВОЗВРАТ
              </span>
            </button>
          </div>

          <!-- Кнопка показать чек возврата -->
          <div
            class="d-flex justify-content-center m-3 btn-group"
            v-if="showChequeRefundButtonVisiblity(order)"
          >
            <button
              type="button"
              class="btn btn-dark"
              data-order="${order._id}"
              @click="buttonOnClick($event, order)"
            >
              ПОКАЗАТЬ ЧЕК ВОЗВРАТА
            </button>
          </div>

          <!-- Кнопка закрыть заказ -->
          <div
            class="d-flex justify-content-center m-3 btn-group"
            v-if="closeOrderButtonVisiblity(order)"
          >
            <button
              type="button"
              class="btn btn-secondary"
              data-order="${order._id}"
              @click="buttonOnClick($event, order)"
              :disabled="order.overdue && !order.closedComment"
            >
              ЗАКРЫТЬ ЗАКАЗ
            </button>
          </div>
        </div>

        <div
          class="text-center m-3"
          v-if="closeOrderButtonVisiblity(order) && order.overdue"
        >
          <p>Заказ закрыт не вовремя, почему?</p>

          <div class="ms-3 me-3 mt-3">
            <textarea
              type="text"
              class="form-control"
              aria-label="Large"
              aria-describedby="inputGroup-sizing-sm"
              v-model="order.closedComment"
              placeholder="Комментарий"
            ></textarea>
          </div>
        </div>

        <!-- Кнопка открыть заказ только для admin -->
        <div
          class="d-flex justify-content-center m-3 btn-group"
          v-if="openOrderButtonVisiblity(order)"
        >
          <button
            type="button"
            class="btn btn-secondary"
            data-order="${order._id}"
            @click="buttonOnClick($event, order)"
          >
            ОТКРЫТЬ ЗАКАЗ
          </button>
        </div>

        <!-- Кнопка сканировать марки -->
        <div v-if="false" class="d-flex justify-content-center m-3 btn-group">
          <button
            type="button"
            class="btn btn-warning"
            data-order="${order._id}"
            @click="startScan(order)"
          >
            СКАНИРОВАТЬ МАРКИ
            <span v-if="'marks' in order">{{ order.marks.length }}</span>
          </button>
        </div>
        <div :id="`scanner-${order._id}`" class="scanner"></div>
      </div>
    </div>
  </div>

  <!-- <pre>
     {{ orders }} 
  </pre> -->
</template>

<script>
import { ref } from "vue";
import { onMounted, onUnmounted, computed } from "vue";
//import { Html5Qrcode } from "html5-qrcode";
import useCommonMixin from "@/components/commonMixin.js";
import OrderMap from "@/components/OrderMap.vue";

export default {
  components: { OrderMap },
  setup() {
    const deliverDate = ref("");
    const orders = ref([]);
    const statusFilter = ref("showOpened");
    const tableOfResults = ref({});
    const checkKkmTasksButtonVisiblity = ref(false);
    const searchText = ref("");
    const intervalId = ref("");
    const mapVisibility = ref(false);
    const selectedOrderId = ref("");

    const {
      settings,
      getSettings,
      scan,
      scanner,
      currentOrder,
      currentProductIndex,
      clearScanner,
    } = useCommonMixin();

    const showMap = () => {
      mapVisibility.value = mapVisibility.value ? false : true;
    };

    let Html5QrcodeObj = undefined;

    const startScan = async (order, productIndex) => {
      if (scannerMode.value == "camera") {
        clearScanner.value =
          currentOrder.value && order._id == currentOrder.value._id
            ? false
            : true;
        currentOrder.value = order;
        currentProductIndex.value = productIndex;
        if (clearScanner.value) {
          scan();
        }
      }

      if (scannerMode.value == "tsd") {
        currentOrder.value = order;
        currentProductIndex.value = productIndex;
      }
    };

    const handleClipboard = (decodedText) => {
      console.log("TSD decodedText", decodedText);
      if (decodedText.length < 33) {
        let confi = window.confirm(`Некорретная марка - ${decodedText}`);
        console.log(confi);
        return;
      }
      if (currentOrder.value && currentProductIndex.value >= 0) {
        if (!("marks" in currentOrder.value.goods[currentProductIndex.value]))
          currentOrder.value.goods[currentProductIndex.value].marks = [];
        let markIndex = currentOrder.value.goods[
          currentProductIndex.value
        ].marks.findIndex((item) => item == decodedText);
        console.log("markIndex", markIndex);
        if (markIndex < 0) {
          currentOrder.value.goods[currentProductIndex.value].marks.push(
            decodedText
          );
          saveOrder(currentOrder.value);
        }
      }
    };

    const isCurrentProduct = computed(() => {
      return (orderId, productIndex) => {
        let result = false;
        if (
          currentOrder.value &&
          currentOrder.value._id == orderId &&
          currentProductIndex.value >= 0 &&
          currentProductIndex.value == productIndex
        ) {
          result = true;
        }

        return result;
      };
    });

    const removeMark = (order, productIndex, markIndex) => {
      let confirm = window.confirm(
        `Удалить марку ${order.goods[productIndex].marks[markIndex]}?`
      );
      if (!confirm) {
        return;
      }

      order.goods[productIndex].marks.splice(markIndex, 1);
      saveOrder(order);
    };

    const userAdmin = () => {
      return localStorage.getItem("username") == "admin";
    };

    const getOrders = async () => {
      console.log("/getOrders");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getOrders/" + deliverDate.value,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa(
                localStorage.getItem("username") +
                  ":" +
                  localStorage.getItem("password")
              ),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        orders.value = await res.json();
        calculatetableOfResults();
      }
    };

    function calculatetableOfResults() {
      tableOfResults.value.qtyOfOrders = 0;
      tableOfResults.value.qtyOfClosedOrders = 0;
      tableOfResults.value.qtyOfCancelledOrders = 0;
      tableOfResults.value.nal = 0;
      tableOfResults.value.karta = 0;
      tableOfResults.value.qr = 0;
      tableOfResults.value.paymentSite = 0;
      tableOfResults.value.bezcheka = 0;
      tableOfResults.value.bottlesReturned = 0;
      tableOfResults.value.bottles = 0;
      tableOfResults.value.bottlesDelivered = 0;
      tableOfResults.value.bottlesDeliveredKzh = 0;
      tableOfResults.value.bottlesDeliveredVart = 0;
      tableOfResults.value.bottlesDeliveredKl = 0;
      tableOfResults.value.vodaKzh = 0;
      tableOfResults.value.vodaVart = 0;
      tableOfResults.value.vodaKl = 0;
      tableOfResults.value.relatedProducts = {};

      orders.value.forEach((order) => {
        if (order.needCheque & !order.chequeRecieved)
          checkKkmTasksButtonVisiblity.value = true;
        tableOfResults.value.qtyOfOrders++;
        if (order.bottlesReturned > -1) {
          tableOfResults.value.bottlesReturned += order.bottlesReturned;
        }

        if (order.closed) {
          tableOfResults.value.qtyOfClosedOrders++;
          if (order.orderStatus == "Отменен")
            tableOfResults.value.qtyOfCancelledOrders++;
          if (order.nal) tableOfResults.value.nal += order.totalCost;
          if (order.karta) tableOfResults.value.karta += order.totalCost;
          if (order.qr) tableOfResults.value.qr += order.totalCost;
          if (order.paymentSite)
            tableOfResults.value.paymentSite += order.totalCost;
          if (order.bezcheka) tableOfResults.value.bezcheka += order.totalCost;
        }

        let products = order.goods;
        products.forEach((product) => {
          if (product.bottles > 0) {
            if (product.sokrashenno == "КЖ") {
              tableOfResults.value.vodaKzh += product.productQty; // Идем по строчке таблицы, поэтому добавляем количество
              if (order.closed && order.orderStatus != "Отменен")
                tableOfResults.value.bottlesDeliveredKzh += product.bottles;
            } else if (product.sokrashenno == "ВАРТ") {
              tableOfResults.value.vodaVart += product.productQty;
              if (order.closed && order.orderStatus != "Отменен")
                tableOfResults.value.bottlesDeliveredVart += product.bottles;
            } else if (product.sokrashenno == "КЛ") {
              tableOfResults.value.vodaKl += product.productQty;
              if (order.closed && order.orderStatus != "Отменен")
                tableOfResults.value.bottlesDeliveredKl += product.bottles;
            }
            tableOfResults.value.bottles += product.bottles;
            if (order.closed && order.orderStatus != "Отменен")
              tableOfResults.value.bottlesDelivered += product.bottles;
          } else {
            if (tableOfResults.value.relatedProducts[product.productName]) {
              tableOfResults.value.relatedProducts[product.productName] +=
                product.productQty;
            } else
              tableOfResults.value.relatedProducts[product.productName] =
                product.productQty;
          }
        });
      });
    }

    const orderColor = (order) => {
      let color = "#ff9999";
      if (order.closed) color = "#90EE90";
      if (order.attention) color = "red";
      return color;
    };

    const orderVisiblity = (order) => {
      // Фильтр по статусам
      if ((statusFilter.value == "showOpened") & order.closed & !order.spinner)
        return false;
      if ((statusFilter.value == "showClosed") & !order.closed & !order.spinner)
        return false;

      // Поиск
      if (
        (searchText.value != "") &
        (searchText.value.length >= 3) &
        !order.search
      )
        return false;

      return true;
    };

    const payTypeVisiblity = (order) => {
      return !order.needCheque & !order.closed & (order.bottlesReturned > -1);
    };

    const fiscalizeButtonVisiblity = (order) => {
      return (
        (order.nal || order.karta || order.qr || order.paymentSite) &
        !order.needCheque
      );
    };

    const fiscalizeRefundButtonVisiblity = (order) => {
      return (
        !order.needChequeRefund && !order.chequeRecievedRefund && userAdmin()
      );
    };

    const showChequeButtonVisiblity = (order) => {
      return order.needCheque & order.chequeRecieved;
    };

    const showChequeRefundButtonVisiblity = (order) => {
      return order.needChequeRefund & order.chequeRecievedRefund;
    };

    const closeOrderButtonVisiblity = (order) => {
      return !order.closed & (order.chequeRecieved || order.bezcheka);
    };

    const openOrderButtonVisiblity = (order) => {
      return (
        order.closed &
        order.bezcheka &
        (localStorage.getItem("username") == "admin")
      );
    };

    const buttonOnClick = async (event, order) => {
      order.spinner = true;
      let button = event.target;
      console.log(button.textContent);
      let btnTextContent = button.textContent.trim();
      if (
        btnTextContent == "НАЛ" ||
        btnTextContent == "КАРТА" ||
        btnTextContent == "QR" ||
        btnTextContent == "БЕЗ ЧЕКА" ||
        btnTextContent == "САЙТ"
      ) {
        order.orderStatus = btnTextContent;
        order["nal"] = false;
        order["karta"] = false;
        order["qr"] = false;
        order["bezcheka"] = false;
        order["paymentSite"] = false;
        order[button.dataset.paytype] = true;
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
          //console.log(savedOrder)
        }
        return;
      }

      if (btnTextContent == "ПРОБИТЬ ЧЕК") {
        button.textContent = "ПОДТВЕРДИТЬ ЧЕК";
        button.style.background = "red";
        button.style.backgroundColor = "red";
        order.spinner = false;

        return;
      }

      if (btnTextContent == "ПРОБИТЬ ВОЗВРАТ") {
        button.textContent = "ПОДТВЕРДИТЬ ВОЗВРАТ";
        button.style.background = "red";
        button.style.backgroundColor = "red";
        order.spinner = false;
        order.isRefund = true;
        return;
      }

      if (
        btnTextContent == "ПОДТВЕРДИТЬ ЧЕК" ||
        btnTextContent == "ПОДТВЕРДИТЬ ВОЗВРАТ"
      ) {
        checkKkmTasksButtonVisiblity.value = true;
        let needChequeProp = order.isRefund ? "needChequeRefund" : "needCheque";
        order[needChequeProp] = true;

        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          let kkmTaskResData = await sendTaskToKkm(order);
          order.spinner = false;
          console.log(kkmTaskResData);
        }
        return;
      }

      if (btnTextContent == "ПОКАЗАТЬ ЧЕК") {
        order.spinner = false;
        window.open("/cheque/" + order.chequeId, "_blank");
        return;
      }

      if (btnTextContent == "ПОКАЗАТЬ ЧЕК ВОЗВРАТА") {
        order.spinner = false;
        window.open("/cheque/" + order.chequeIdRefund, "_blank");
        return;
      }

      if (btnTextContent == "ЗАКРЫТЬ ЗАКАЗ") {
        let deadline =
          order.deliveryDate.substring(0, 11) +
          order.deliveryTimeCeil.substring(11);
        deadline = new Date(deadline);
        deadline = new Date(deadline.getTime() + 10 * 60000)
          .toISOString()
          .substring(0, 19);
        let today = new Date().toISOString().substring(0, 19);

        console.log("today", today);
        console.log("deadline", deadline);
        order.overdue = today > deadline;

        if (today > deadline && !order.closedComment) {
          order.overdue = true;
          order.spinner = false;
          console.log("overdue");
        } else {
          order.closed = true;
          order.closedDate = today;

          let savedOrder = await saveOrder(order);
          if (savedOrder) {
            order.spinner = false;
          }
        }

        return;
      }

      if (btnTextContent == "ОТКРЫТЬ ЗАКАЗ") {
        order.closed = false;
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
        }
        return;
      }
    };

    async function saveOrder(order) {
      console.log("/saveOrder");
      let res = await fetch(process.env.VUE_APP_API_URL + "/saveOrder", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("username") +
                ":" +
                localStorage.getItem("password")
            ),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(order),
      });
      console.log(res.status);
      let resJson = undefined;
      if (res.status == 200) {
        resJson = await res.json();
      }
      return resJson;
    }

    async function sendTaskToKkm(order) {
      console.log("/sendTaskToKkm");
      let res = await fetch(process.env.VUE_APP_API_URL + "/sendTaskToKkm", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("username") +
                ":" +
                localStorage.getItem("password")
            ),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(order),
      });
      console.log(res.status);
      let resJson = undefined;
      if (res.status == 200) {
        resJson = await res.json();
      }
      return resJson;
    }

    const checkKkmTasks = async () => {
      checkKkmTasksButtonVisiblity.value = false;
      console.log("/checkKkmTasks");
      let res = await fetch(process.env.VUE_APP_API_URL + "/checkKkmTasks", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("username") +
                ":" +
                localStorage.getItem("password")
            ),
        },
      });
      console.log(res.status);
      let updatedKkmTasks = undefined;
      if (res.status == 200) {
        updatedKkmTasks = await res.json();
        console.log(updatedKkmTasks);
        if (updatedKkmTasks.length > 0 || orders.value.length == 0)
          await getOrders();
      }
    };

    const findOrders = () => {
      if (searchText.value.length < 3) return;
      searchText.value = searchText.value.toLowerCase();
      let stringValue = "";
      for (const order of orders.value) {
        // console.log(order)
        order.search = false;
        for (const key in order) {
          stringValue = order[key].toString().toLowerCase();
          if (stringValue.indexOf(searchText.value) != -1) {
            order.search = true;
            console.log(order);
          }
        }
      }
    };

    const isMarksCollected = computed(() => {
      return (order) => {
        let result = true;
        order.goods.forEach((goodRow) => {
          if (goodRow.bottles) {
            result =
              goodRow.productQty != goodRow.marks.length ? false : result;
          }
        });
        if ("chequeWithoutMarks" in order) {
          result = result || order.chequeWithoutMarks;
        }
        return result;
      };
    });

    const scannerMode = computed(() => {
      let result = "camera";
      if (
        "scannerMode" in settings.value &&
        settings.value.scannerMode == "tsd"
      )
        result = "tsd";

      return result;
    });

    onMounted(async () => {
      console.log("onMounted");
      let today = new Date().toISOString().slice(0, 10);
      deliverDate.value = today;
      await getOrders();
      console.log(Html5QrcodeObj);
      await getSettings();

      intervalId.value = window.setInterval(() => {
        checkKkmTasks();
      }, 5000);

      if (scannerMode.value == "tsd") {
        document.addEventListener("paste", (event) => {
          let paste = (event.clipboardData || window.clipboardData).getData(
            "text"
          );
          handleClipboard(paste);
          event.preventDefault();
        });
      }
    });

    const onOpenOrder = (orderId) => {
      console.log("onOpenOrder", orderId);
      selectedOrderId.value = orderId;
    };

    onUnmounted(async () => {
      console.log("onUnmounted");
      clearInterval(intervalId.value);
    });

    return {
      deliverDate,
      orders,
      getOrders,
      orderColor,
      orderVisiblity,
      statusFilter,
      tableOfResults,
      checkKkmTasksButtonVisiblity,
      payTypeVisiblity,
      fiscalizeButtonVisiblity,
      showChequeButtonVisiblity,
      closeOrderButtonVisiblity,
      openOrderButtonVisiblity,
      buttonOnClick,
      checkKkmTasks,
      searchText,
      findOrders,
      userAdmin,
      settings,
      getSettings,
      scan,
      scanner,
      startScan,
      currentProductIndex,
      currentOrder,
      isCurrentProduct,
      removeMark,
      clearScanner,
      isMarksCollected,
      scannerMode,
      fiscalizeRefundButtonVisiblity,
      showChequeRefundButtonVisiblity,
      mapVisibility,
      showMap,
      onOpenOrder,
      selectedOrderId,
    };
  },
};
</script>

<style>
.currentProduct {
  background-color: gainsboro;
}

.blink {
  animation-name: blinker;
  animation-iteration-count: 3;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-duration: 2s;
  -webkit-animation-name: blinker;
  -webkit-animation-iteration-count: 3;
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-animation-duration: 2s;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
</style>
