<template>
  <div class="container">
    <h1>Настройки</h1>
    <div class="row">
      <div class="col-3">
        РЕЖИМ СКАНЕРА
      </div>
      <div class="col">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn btn-light">
            <input
              type="radio"
              name="options"
              value="camera"
              autocomplete="off"
              v-model="settings.scannerMode"
              @click="edit = true"
            />
            КАМЕРА
          </label>
          <label class="btn btn-light">
            <input
              type="radio"
              name="options"
              value="tsd"
              autocomplete="off"
              v-model="settings.scannerMode"
              @click="edit = true"

            />
            ТСД
          </label>
        </div>
      </div>
    </div>

    <div v-if="edit" class="row mt-2">
      <button class="btn btn-primary" @click="saveAllSettings()">Сохранить</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  setup() {
    const {
      settings,
      getSettings,
      saveSettings,
      scan,
      scanner,
    } = useCommonMixin();

    const saveAllSettings = () => {
      saveSettings();
      edit.value = false;
    };

    const edit = ref(false);
    const cameras = ref([]);


    onMounted(async () => {
      //console.log("Html5QrcodeObj", Html5QrcodeObj);
      //await getCameras();
      await getSettings();
    });

    return {
      saveAllSettings,
      cameras,
      settings,
      scan,
      scanner,
      edit,
    };
  },
};
</script>
