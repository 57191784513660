<template>
  <div class="card m-3" style="max-width: 30rem">
    <div class="card-body">
      <h5 class="card-title">ООО «Компания «Северный источник»</h5>
      <h5 class="card-title">ИНН: 1001345507</h5>
      <div class="card-text">
        <div class="row">
          <div class="col-sm">
            <div class="text-wrap" v-for="(item, index) in order.goods" :key="index">
              {{ index + 1 }}. {{ item.productName }}: {{ item.productQty }} X
              {{ item.productPrice }} = {{ item.productSum }}<br />
            </div>
            <h3>ИТОГ: {{ fiscalParams.sumFD }} руб.</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-sm">
            <div v-if="order.nal">НАЛИЧНЫМИ<br /></div>
            <div v-if="order.karta || order.qr">БЕЗНАЛИЧНЫМИ<br /></div>
            
            <span v-if="isRefund">
              ВОЗВРАТ ПРИХОДА <br />
            </span>

            <span v-if="!isRefund">
              ПРИХОД<br />
            </span>

            СУММА БЕЗ НДС<br />
            СНО: УСН ДОХОД-РАСХОД<br />
            <div v-if="fiscalParams.timeFD">Дата: {{ fiscalParams.timeFD.slice(0, 16).replace("T", " ") }}<br /></div>
            Смена: {{ fiscalParams.shiftNumber }}<br />
            Кассир: Бобко Н.Н.<br />
            E-mail: {{ order.email }}<br />
            Сайт ФНС: {{ fiscalParams.fnsUrl }}<br />
            РН: {{ fiscalParams.registrationNumber }}<br />
          </div>

          <div class="col-sm">
            ФД: {{ fiscalParams.numFD }}<br />
            ФН: {{ fiscalParams.fnFD }}<br />
            ФП: {{ fiscalParams.fpFD }}<br />
            <qrcode-vue :value="qr" :size="170"/>  
          </div>
        </div>

        
      </div>
    </div>
  </div>

  <!-- <pre>
  {{ qr }}
</pre
  > -->
</template>

<script>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue,
  },
  setup() {
    const route = useRoute();
    const fiscalParams = ref({});
    const order = ref({});
    const qr = ref("");
    const isRefund = ref(false)

    const getCheque = async () => {
      console.log("/getKkmTask/" + route.params.chequeId);
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getKkmTask/" + route.params.chequeId,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa(
                localStorage.getItem("username") +
                  ":" +
                  localStorage.getItem("password")
              ),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        let cheque = await res.json();
        let resData = JSON.parse(cheque.resData);
        order.value = cheque.order;
        console.log('resData', resData);
        fiscalParams.value = resData;
        //fiscalParams.value.fnFD = "7381440800296633" //до 21.08.2024 - 9961440300133659
        isRefund.value = 'isRefund' in cheque ? cheque.isRefund : false
        qr.value =
          `t=${fiscalParams.value.timeFD
            .slice(0, 17)
            .replace(/-/g, "")
            .replace(/:/g, "")}&` +
          `s=${fiscalParams.value.sumFD}&fn=${fiscalParams.value.fnFD}&i=${fiscalParams.value.numFD}&` +
          `fp=${fiscalParams.value.fpFD}&n=${isRefund.value ? '2' : '1'}`;
      }
    };

    onMounted(getCheque);

    return {
      getCheque,
      order,
      fiscalParams,
      qr,
      isRefund,
    };
  },
};
</script>
