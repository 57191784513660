<template>
  <div class="container">
    <div ref="yandexMap" style="width: 100%; height: 600px"></div>

    <div
      v-for="order in ordersFilter"
      :key="order._id"
      :id="`marker-${order._id}`"
      :class="{ 'current-marker': order.showDetails }"
    >
      <div class="">
        <button
          class="btn btn-sm btn-light p-1"
          @click="showOrderDetails(order)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1.5em"
            viewBox="0 0 512 512"
            :style="{'fill': getOrderColor(order)}"
          >
            <path
              d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
            />
          </svg>
        </button>
      </div>

      <div v-if="order.showDetails">
        <div class="row">
          <div class="btn-group" role="group">
            <button class="btn btn-sm btn-light p-1" @click="openYmap(order)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.5em"
                viewBox="0 0 512 512"
                :style="{'fill': getOrderColor(order)}"
              >
                <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                />
              </svg>
            </button>

            <button class="btn btn-sm btn-light p-1" @click="openOrder(order)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.5em"
                viewBox="0 0 384 512"
                :style="{'fill': getOrderColor(order)}"
              >
                <path
                  d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div style="background-color: white">
          <div class="row border border-secondary mx-0 rounded-2">
            <span class="lh-1 marker-address text-center fw-bold px-1 ">{{
              orderTime(order)
            }}</span>
          </div>
          <div class="row border border-secondary mx-0 rounded-2">
            <span class="lh-1 marker-address px-1">{{
              order.orderNumber
            }}</span>
          </div>
          <div class="row  border border-secondary mx-0 rounded-2">
            <span class="lh-1 marker-address px-1">{{
              order.deliveryAdress
            }}</span>
          </div>
          <div class="row  border border-secondary mx-0 rounded-2">
            <span class="lh-1 marker-address px-1">{{ order.client }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, watch, computed } from "vue";
// import * as Vue from 'vue'
import useCommonMixin from "@/components/commonMixin.js";

export default {
  props: { orders: { default: [] } },
  setup(props, { emit }) {
    const { scrollToId } = useCommonMixin();
    const yandexMap = ref(null);
    let mapObj = null;

    const ordersFilter = computed(() => {
      let result = [];
      if (props.orders) {
        result = props.orders.filter((item) => !item.closed);
      }
      return result;
    });

    const driversColor = ref([])

    onMounted(() => {
      getDriversColor()
      loadScript();
    });

    const loadScript = async () => {
      let scriptId = "map-api-script";
      let mapAlreadyAttached = !!document.getElementById(scriptId);
      if (mapAlreadyAttached) {
        if (window.ymaps3) {
          await initMap();
        }
      } else {
        // window.mapApiInitialized = initMap;

        let script = document.createElement("script");
        script.id = scriptId;
        script.src =
          "https://api-maps.yandex.ru/v3/?apikey=7ad40781-3686-4d00-a25a-58d79896a766&lang=ru_RU";
        document.body.appendChild(script);

        script.addEventListener("load", initMap);
      }
    };

    const initMap = async () => {
      const ymaps3 = window.ymaps3;
      await ymaps3.ready;

      const {
        YMap,
        YMapControls,
        YMapDefaultSchemeLayer,
        YMapDefaultFeaturesLayer,
      } = ymaps3;
      const { YMapZoomControl } = await ymaps3.import(
        "@yandex/ymaps3-controls@0.0.1"
      );

      mapObj = new YMap(yandexMap.value, {
        location: {
          center: [34.346869, 61.785014],
          zoom: 12,
        },
      });
      // Добавляем слой для отображения схематической карты
      mapObj.addChild(new YMapDefaultSchemeLayer());
      mapObj.addChild(new YMapDefaultFeaturesLayer({ zIndex: 1800 }));
      mapObj.addChild(
        new YMapControls({ position: "right" }, [new YMapZoomControl({})])
      );
      await updateMarkers();
    };

    const updateMarkers = async () => {
      const ymaps3 = window.ymaps3;

      await ymaps3.ready;

      const { YMapMarker } = ymaps3;
      //  const {YMapDefaultMarker} = await ymaps3.import('@yandex/ymaps3-markers@0.0.1');

      await nextTick();
      for (const order of props.orders) {
        let markerElement = document.getElementById(`marker-${order._id}`);
        const marker = new YMapMarker(
          {
            //source: "markerSource",
            coordinates: order.coordinates,
            draggable: false,
            mapFollowsOnDrag: false,
          },
          markerElement
        );

        mapObj.addChild(marker);
      }
    };

    const openOrder = (order) => {
      console.log("openOrder", order._id);
      scrollToId(`order-${order._id}`);
      emit('openOrder', order._id)
    };

    const openYmap = (order) => {
      let url =
        "yandexmaps://maps.yandex.ru/?pt=" +
        order.coordinates[0] +
        "," +
        order.coordinates[1] +
        "&z=12&l=map";
      window.open(url, "_self");
      console.log("openYmap");
    };

    const showOrderDetails = (order) => {
      for (let orderItem of props.orders) {
        if (orderItem._id != order._id) orderItem.showDetails = false;
      }
      console.log("showOrderDetails", order);
      order.showDetails = order.showDetails ? false : true;
    };

    const orderTime = (order) => {
      let result =
        order.deliveryTimeFloor.substring(11, 16) +
        "-" +
        order.deliveryTimeCeil.substring(11, 16);
      return result;
    };

    const getOrderColor = (order) => {
        let color = 'black'
        let driver = order.courier
        let driverIndex = driversColor.value.findIndex(item => item.name == driver)
        if (driverIndex >= 0) color = driversColor.value[driverIndex].color

        //console.log('getOrderColor', driver, color);
        return color
    }

    const getDriversColor = async () => {
      //console.log("/getDriversColor");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/driverColor/",
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa(
                localStorage.getItem("username") +
                  ":" +
                  localStorage.getItem("password")
              ),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        driversColor.value = await res.json();
      }
    };

    watch(
      () => props.orders,
      () => {
        console.log("watch orders", props.order);
        updateMarkers();
      }
    );

    return {
      yandexMap,
      openOrder,
      openYmap,
      showOrderDetails,
      orderTime,
      ordersFilter,
      driversColor,
      getOrderColor,
    };
  },
};
</script>

<style>
.marker-address {
  font-size: small;
}

.current-marker {
  z-index: 1000;
}
</style>
